import { css } from 'styled-components';
import {
  getSpacing, SpacingName, TypographyName, getTypography, ThemedFunctor, FontWeight, ellipsis,
} from '@doveit/lego';

export const base = css`
  cursor: pointer;
  overflow: hidden;
`;

export const title: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.BODY)}
  font-weight: ${FontWeight.BOLD};
  line-height: 1.1 !important;

  a {
    text-decoration: none;
    color: ${theme.palettes.neutrals.darkest};
    text-transform: capitalize;
    ${ellipsis()}
  }
`;

export const suburb: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.SMALL)}
  color: ${theme.palettes.neutrals.dark};
  ${ellipsis()}
`;

export const price: ThemedFunctor = ({
  theme,
  $small,
}) => css`
  ${getTypography(theme, $small ? TypographyName.BODY : TypographyName.H4)}
  font-weight: ${FontWeight.BOLD};
  color: ${theme.palettes.brand.primary};
  white-space: nowrap;
`;

export const details: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLEST)}
  display: flex;
  align-items: center;
`;

export const highlights: ThemedFunctor = ({
  theme,
}) => css`
  display: flex;
  align-items: center;

  &:last-child {
    margin-left: .4rem;
    border-left: 1px solid ${theme.palettes.neutrals.light};
  }
`;

export const highlight: ThemedFunctor = ({
  theme,
}) => css`
  border-right: 1px solid ${theme.palettes.neutrals.light};
  padding: 0 .4rem;
  display: flex;
  flex-direction: column;

  &:last-child {
    border-right: none;
    padding-right: 0;
  }
`;

export const highlightValue: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.SMALL)}
  font-weight: ${FontWeight.BOLD};
  line-height: 1;
  margin-top: 3px;
  white-space: nowrap;
`;

export const highlightLabel: ThemedFunctor = ({
  theme,
}) => css`
  ${getTypography(theme, TypographyName.SMALLER)}
  line-height: 1;
  color: ${theme.palettes.neutrals.dark};
`;

export const image: ThemedFunctor = ({
  theme,
}) => css`
  ${getSpacing.marginTop(theme, SpacingName.SMALLER)}
`;
