import React, { FunctionComponent, ReactChild } from 'react';
import { SpacingName } from '@doveit/lego';
import PageSection from '../../../components/page-section/PageSection';
import { TextAlign } from '../../../types';
import Tile from '../../../components/tile/Tile';
import CardsHList from '../../../components/cards-hlist/CardsHList';
import { URLObject } from '../../../utils/links';
import { requireFromStaticAsset } from '../../../utils/assetsUtils';

export interface CitiesSectionProps {
  title: string,
  description?: string,
  cities: {
    title?: string,
    anchorTitle?: string,
    picture?: string,
    onClick?: VoidFunction,
    urlObject?: URLObject,
  }[],
  cta?: ReactChild,
}

export const CitiesSection: FunctionComponent<CitiesSectionProps> = ({
  title,
  description,
  cities = [],
  cta,
}) => (
  <PageSection>
    <PageSection.Wrapper>
      <PageSection.Title textAlign={TextAlign.CENTER}>
        {title}
      </PageSection.Title>
      {description && (
        <PageSection.Description
          textAlign={TextAlign.CENTER}
          text={description}
        />
      )}
    </PageSection.Wrapper>
    <PageSection.Wrapper withoutPad>
      <PageSection.Block>
        <CardsHList
          inline={6}
          itemWidth="9rem"
          sideSpacing={SpacingName.SMALL}
        >
          {cities.map((city, i) => (
            <CardsHList.Item
              key={city.title}
              data-ref={`city-${city.title}-${i}`}
            >
              <Tile
                title={city.title}
                anchorTitle={city.anchorTitle}
                image={{
                  src: requireFromStaticAsset(city.picture),
                  alt: city.title,
                }}
                urlObject={city.urlObject}
                onClick={city.onClick}
                loading="lazy"
              />
            </CardsHList.Item>
          ))}
        </CardsHList>
      </PageSection.Block>
    </PageSection.Wrapper>
    {cta && (
      <PageSection.Wrapper>
        <PageSection.Actions textAlign={TextAlign.CENTER}>
          {cta}
        </PageSection.Actions>
      </PageSection.Wrapper>
    )}
  </PageSection>
);

export default CitiesSection;
