import React from 'react';
import { Button, ButtonColor, ButtonVariant } from '@doveit/lego';
import PageSection from '../../../components/page-section/PageSection';
import {
  goToPage, buildListingURLObject, buildSerpURLObject,
} from '../../../utils/links';
import { TextAlign } from '../../../types';
import PropertySearchResultCardList from '../../../components/property-search-result-card-list/PropertySearchResultCardList';
import { PropertySearchResult } from '../../../../services/dtos';

export interface OurPropertiesProps {
  title: string,
  description: string,
  cta?: string,
  latestProperties: PropertySearchResult[],
}

const OurProperties: React.FC<OurPropertiesProps> = ({
  title,
  description,
  cta,
  latestProperties,
}) => {
  const properties = React.useMemo(() => latestProperties.map((searchResult) => ({
    searchResult,
    urlObject: buildListingURLObject(searchResult),
    onClick: () => goToPage(buildListingURLObject(searchResult)),
  })), [latestProperties]);

  return (
    <PageSection>
      <PageSection.Wrapper>
        <PageSection.Title textAlign={TextAlign.CENTER}>
          {title}
        </PageSection.Title>

        <PageSection.Description
          text={description}
          textAlign={TextAlign.CENTER}
        />
      </PageSection.Wrapper>
      <PageSection.Wrapper>
        <PageSection.Block>
          <PropertySearchResultCardList items={properties} />
        </PageSection.Block>
        {cta && (
          <PageSection.Actions textAlign={TextAlign.CENTER}>
            <Button
              data-ref="our-properties-section-action"
              color={ButtonColor.PRIMARY}
              variant={ButtonVariant.OUTLINE}
              label={cta}
              aria-label={cta}
              href={buildSerpURLObject().as}
              onClick={() => goToPage(buildSerpURLObject())}
            />
          </PageSection.Actions>
        )}
      </PageSection.Wrapper>
    </PageSection>
  );
};

export default OurProperties;
