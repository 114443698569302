/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FunctionComponent } from 'react';
import { Image } from '@doveit/lego';
import Link from 'next/link';
import * as styles from './PropertySearchResultCard.style';
import { PropertySearchResult } from '../../../services/dtos/property';
import { URLObject } from '../../utils/links';

export interface PropertySearchResultCardProps {
  searchResult: PropertySearchResult,
  urlObject: URLObject,
  onClick: VoidFunction,
}

export const PropertySearchResultCard: FunctionComponent<PropertySearchResultCardProps> = ({
  searchResult,
  urlObject,
  onClick,
}) => (
  <article
    css={styles.base}
    onClick={onClick}
  >
    <div css={styles.title}>
      <Link
        href={urlObject.url}
        as={urlObject.as}
      >
        {searchResult.route}
      </Link>
    </div>
    <div css={styles.suburb}>
      {[searchResult.locality, searchResult.suburb].filter((i) => i).join(' - ')}
    </div>
    <div css={styles.details}>
      <div
        css={styles.price}
        $small={searchResult.price.length >= 10}
      >
        {searchResult.price}
      </div>
      <div css={styles.highlights}>
        {searchResult.highlights.slice(0, 3).map((item) => (
          <div
            key={item.label}
            css={styles.highlight}
            data-ref="property-highlight"
          >
            <strong css={styles.highlightValue}>
              {item.value}
            </strong>
            <div css={styles.highlightLabel}>
              {item.label}
            </div>
          </div>
        ))}
      </div>
    </div>
    {searchResult.coverImageSet && (
      <div css={styles.image}>
        <Image
          src={searchResult.coverImageSet.small}
          alt={`Anteprima ${searchResult.route}`}
          loading="lazy"
          responsive
        />
      </div>
    )}
  </article>
);

export default PropertySearchResultCard;
