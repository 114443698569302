import { SpacingName } from '@doveit/lego';
import React, { FunctionComponent } from 'react';
import CardsHList from '../cards-hlist/CardsHList';
import PropertySearchResultCard, { PropertySearchResultCardProps } from '../property-search-result-card/PropertySearchResultCard';

export interface PropertySearchResultCardListProps {
  items: PropertySearchResultCardProps[],
  inline?: number,
  sideSpacing?: SpacingName,
}

const PropertySearchResultCardList: FunctionComponent<PropertySearchResultCardListProps> = ({
  items,
  inline,
  sideSpacing = SpacingName.SMALL,
}) => (
  <CardsHList
    inline={inline}
    sideSpacing={sideSpacing}
  >
    {items.map((item) => (
      <CardsHList.Item key={item.searchResult.id}>
        <PropertySearchResultCard
          {...item}
        />
      </CardsHList.Item>
    ))}
  </CardsHList>
);

export default PropertySearchResultCardList;
