import React, { LegacyRef, useCallback } from 'react';
import { useInView } from 'react-hook-inview';
import {
  BreakpointQueryName, Button, ButtonColor, ButtonVariant,
} from '@doveit/lego';
import dynamic from 'next/dynamic';
import { NextPage } from 'next';
import SectionDivider from '../client/components/section-divider/SectionDivider';
import InfoSection, { InfoSectionProps } from '../client/templates/common/info-section/InfoSection';
import AppLayout from '../client/layouts/app-layout/AppLayout';
import { requireFromStaticAsset, requireResourcesFromStaticAssets } from '../client/utils/assetsUtils';
import CitiesSection from '../client/templates/common/cities-section/CitiesSection';
import OurServicesSection, { OurServicesSectionProps } from '../client/templates/common/our-services-section/OurServicesSection';
import {
  goToPage,
  buildAgencyByLocalityURLObject,
  buildEvaluationURLObject,
  buildAgenciesURLObject,
  buildServicesURLObject,
} from '../client/utils/links';
import BottomBar, { BottomBarProps } from '../client/templates/common/bottom-bar/BottomBar';
import OurProperties, { OurPropertiesProps } from '../client/templates/homepage/our-properties/OurProperties';
import PageHead, { PageHeadProps } from '../client/components/page-head/PageHead';
import { getStructuredData, getOrganizationStructuredData } from '../data/structured';
import LatestSoldSection, { LatestSoldSectionProps } from '../client/templates/common/latest-sold-section/LatestSoldSection';
import LogosSection, { LogosSectionProps } from '../client/templates/common/logos-section/LogosSection';
import { City, HeroSection } from '../client/types';
import { ReviewsSectionProps } from '../client/templates/common/reviews-section/ReviewsSection';
import { getServices } from '../services/our-services/ourServices';
import Hero, { HeroProps } from '../client/templates/common/hero/Hero';
import { getHighlightedCities } from '../services/cities/cities';
import { withError, withGetServerSideError } from '../client/hocs/with-server-side-error/withServerSideError';
import { importDynamicData } from '../services/importDynamicData';
import LeadInvitationPopup from '../client/components/lead-invitation-popup/LeadInvitationPopup';
import { getAll } from '../services/properties/properties';
import { Status } from '../domain/status';
import { SortOrder } from '../domain/pagination';
import { getLogger } from '../logger/isomorphic';

export interface IndexPageProps {
  SEO: PageHeadProps['SEO'] & { image: string },
  introSection: HeroSection,
  latestSoldProperties: LatestSoldSectionProps,
  press: LogosSectionProps,
  infoSection: InfoSectionProps,
  ourServicesSection: OurServicesSectionProps,
  ourPropertiesSection: OurPropertiesProps,
  cities: City[],
  citiesSection: {
    title: string,
    description: string,
  },
  reviews: ReviewsSectionProps,
  bottomBar: BottomBarProps,
}

const logger = getLogger();

const ReviewsSection = dynamic(() => import('../client/templates/common/reviews-section/ReviewsSection'), {
  ssr: false,
});

const agenciesURLObject = buildAgenciesURLObject();
const servicesURLObject = buildServicesURLObject();
const evaluationURLObject = buildEvaluationURLObject();

const HeroWithRef = React.forwardRef((props: HeroProps & HeroSection, ref: LegacyRef<HTMLDivElement>) => (
  <div ref={ref}>
    <Hero
      {...props}
      primarySlot={(
        <Button
          label={props.cta.label}
          onClick={() => goToPage(evaluationURLObject)}
          href={evaluationURLObject.as}
        />
      )}
    />
  </div>
));

export const IndexPage: NextPage<IndexPageProps> = ({
  SEO,
  introSection,
  infoSection,
  ourPropertiesSection,
  latestSoldProperties,
  press,
  ourServicesSection,
  cities,
  citiesSection,
  reviews,
  bottomBar,
}) => {
  const [stickyRef, isTargetVisible] = useInView({
    threshold: 0,
  });

  const goToServicesPage = useCallback(() => {
    goToPage(servicesURLObject);
  }, []);

  const goToAgenciesPage = useCallback(() => {
    goToPage(agenciesURLObject);
  }, []);

  const goToEvaluationPage = useCallback(() => {
    goToPage(buildEvaluationURLObject());
  }, []);

  return (
    <AppLayout activePage="homepage">
      <PageHead
        SEO={SEO}
        image={{ url: requireFromStaticAsset(SEO.image) }}
        structuredData={getStructuredData(
          getOrganizationStructuredData(),
        )}
      />

      <LeadInvitationPopup
        trigger={{
          element: <HeroWithRef
            title={introSection.title}
            description={introSection.description}
            cta={introSection.cta}
            backgroundImage={requireResourcesFromStaticAssets(introSection.backgroundImage)}
            ref={stickyRef}
          />,
          when: 'out-of-view',
        }}
      />

      <OurServicesSection
        {...ourServicesSection}
        callToAction={{
          label: 'Scopri tutti i servizi',
          urlObject: servicesURLObject,
          onClick: goToServicesPage,
        }}
      />

      <SectionDivider />

      <InfoSection {...infoSection} />

      <SectionDivider />

      <OurProperties
        title={ourPropertiesSection.title}
        description={ourPropertiesSection.description}
        latestProperties={ourPropertiesSection.latestProperties}
        cta={ourPropertiesSection.cta}
      />

      <SectionDivider />

      <CitiesSection
        {...citiesSection}
        cities={cities.map((city) => {
          const urlObject = buildAgencyByLocalityURLObject({ locality: city.locality });
          return {
            ...city,
            anchorTitle: `Agenzia Immobiliare ${city.title}`,
            urlObject,
            onClick: () => goToPage(urlObject),
          };
        })}
        cta={(
          <Button
            label="Scopri tutte le città"
            variant={ButtonVariant.OUTLINE}
            color={ButtonColor.PRIMARY}
            href={agenciesURLObject.as}
            onClick={goToAgenciesPage}
          />
        )}
      />

      <SectionDivider />

      <LatestSoldSection {...latestSoldProperties} />

      <ReviewsSection
        title={reviews.title}
        showReviewLink
      />

      <LogosSection
        title={press.title}
        items={press.items}
        inlineItems={6}
      />

      <BottomBar
        hiddenFrom={BreakpointQueryName.MD}
        expanded={!isTargetVisible}
        label={bottomBar.label}
        title={bottomBar.title}
        headline={bottomBar.headline}
        onClick={goToEvaluationPage}
      />
    </AppLayout>
  );
};

export const getServerSideProps = withGetServerSideError(async () => {
  const [common, pageData, services, cities, latestProperties] = await Promise.all([
    importDynamicData('common.yml'),
    importDynamicData('pages/homepage.yml'),
    getServices(),
    getHighlightedCities(),
    getAll(
      {
        status: Status.LIVE,
      },
      {
        size: 8,
        sort: {
          published_at: SortOrder.DESC,
        },
      },
    ).catch((err) => {
      logger.warn({ err }, 'Error while retrieving properties');
      return {
        content: [],
      };
    }),
  ]);

  return {
    props: {
      ...common,
      ...pageData,
      ourPropertiesSection: {
        ...common.ourPropertiesSection,
        latestProperties: latestProperties.content,
      },
      cities,
      ourServicesSection: {
        ...common.ourServicesSection,
        services,
      },
    },
  };
});

export default withError(IndexPage);
