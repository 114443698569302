import {
  Button, ButtonColor, ButtonVariant, SpacingName,
} from '@doveit/lego';
import React, { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import PageSection from '../../../components/page-section/PageSection';
import { TextAlign } from '../../../types';
import { buildSoldPropertiesURLObject, goToPage } from '../../../utils/links';

const ViewLatestSoldProperties = dynamic(() => import('../../../containers/view-latest-sold-properties/ViewLatestSoldProperties'), {
  ssr: false,
});

export interface LatestSoldSectionProps {
  title: string,
  cta?: string,
}

const LatestSoldSection: FunctionComponent<LatestSoldSectionProps> = ({
  title,
  cta,
}) => (
  <PageSection data-ref="latest-sold-section">
    <PageSection.Wrapper>
      <PageSection.Title textAlign={TextAlign.CENTER}>
        {title}
      </PageSection.Title>
    </PageSection.Wrapper>
    <PageSection.Wrapper withoutPad>
      <PageSection.Block>
        <ViewLatestSoldProperties sideSpacing={SpacingName.SMALL} />
      </PageSection.Block>
    </PageSection.Wrapper>
    {cta && (
      <PageSection.Wrapper>
        <PageSection.Actions textAlign={TextAlign.CENTER}>
          <Button
            data-ref="latest-sold-section-action"
            color={ButtonColor.PRIMARY}
            variant={ButtonVariant.OUTLINE}
            label={cta}
            aria-label={cta}
            href={buildSoldPropertiesURLObject().as}
            onClick={() => goToPage(buildSoldPropertiesURLObject())}
          />
        </PageSection.Actions>
      </PageSection.Wrapper>
    )}
  </PageSection>
);

export default LatestSoldSection;
